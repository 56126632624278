import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useContextApi } from "../../context/index";
import {
  TabMenu,
  FormControls,
  useShippingMethodEditForm,
  OptionDialog,
  ServiceDialog,
  useValidationSchemas,
} from "./index";

export default function EditShippingMethodDialogForm(props) {
  const { shippingMethod, shippingMethods, submitMethod, edit } = props;
  const {
    optionDialogOpen,
    toggleOptionDialogOpen,
    serviceDialogOpen,
    toggleServiceDialogOpen,
  } = useShippingMethodEditForm();
  const { mainValidationSchema, dialogValidationSchema } = useValidationSchemas(
    {
      shippingMethods: [...shippingMethods.data],
      shippingMethod: { ...shippingMethod },
    }
  );
  const { formatDate } = useContextApi();

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        submitMethod(values);
      }}
      initialValues={{
        ...shippingMethod,
        activationDate: formatDate(
          shippingMethod.activationDate,
          "YYYY-MM-DDTHH:MM"
        ),
      }}
      validationSchema={mainValidationSchema}
    >
      {(formikProps) => (
        <Form>
          <FormControls
            dirty={formikProps.dirty}
            handleReset={formikProps.handleReset}
            name={shippingMethod.name}
            edit={edit}
          />
          <TabMenu
            handleBlur={formikProps.handleBlur}
            handleChange={formikProps.handleChange}
            setFieldValue={formikProps.setFieldValue}
            values={{ ...formikProps.values }}
            errors={{ ...formikProps.errors }}
            touched={{ ...formikProps.touched }}
            submitCount={formikProps.submitCount}
            toggleOptionDialogOpen={toggleOptionDialogOpen}
            toggleServiceDialogOpen={toggleServiceDialogOpen}
          />
          <OptionDialog
            optionDialogOpen={optionDialogOpen}
            toggleOptionDialogOpen={toggleOptionDialogOpen}
            parentValues={{ ...formikProps.values }}
            setParentFieldValue={formikProps.setFieldValue}
            validationSchema={dialogValidationSchema}
            edit={optionDialogOpen.id !== "create"}
          />
          <ServiceDialog
            serviceDialogOpen={serviceDialogOpen}
            toggleServiceDialogOpen={toggleServiceDialogOpen}
            parentValues={{ ...formikProps.values }}
            setParentFieldValue={formikProps.setFieldValue}
            edit={serviceDialogOpen.id !== "create"}
            validationSchema={dialogValidationSchema}
          />
        </Form>
      )}
    </Formik>
  );
}
